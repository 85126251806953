<template>
	<div class="aboutOurs">
		<common-head url="/newHome" :title="$t('tba.aboutus')" :is-return="true"></common-head>

		<div class="layoutBox bgWhite">
			<div class="contact">
				<div class="ico">
					<img :src="logo_img"  v-if="logo_img != ''"/>
					<p class="mainFontColor">{{ $t('tba.copyVersion') }}: 3-3.9</p>
				</div>
				<div class="contact-item" v-for="(item,index) in list" :key="index" @click="onClickLeft(item)">
					<!-- <span class="label mainFontLightColor">{{ $t('tba.weiXin') }}</span> -->
					<div class="label mainFontLightColor">{{item.title}}</div>
					<img class="icon" src="@/assets/imgs/icon/right.png" alt="" />
					<!-- <span class="mainFontColor">{{ weixin }}</span> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import commonHead from '@/components/commonHead';

export default {
	name: 'aboutOurs',
	components: {
		commonHead
	},
	data() {
		return {
			email: '',
			weixin: '',
			list: [],
			logo_img: ''
		};
	},

	created() {
		this.getAbout();
		this.GetSystemImgList()
	},

	methods: {
		onClickLeft(e) {				
			this.$router.push({
				path: '/aboutoursmessage',
				query: {
					msg: e,
				}
			})
		},
		getAbout() {
			this.$post2('Article/Api/Index/faqs', {}).then(res => {
				this.list = res
			});
		},
		//获取logo图
		GetSystemImgList() {
			this.$post2('User/Api/Passport/GetSystemImgList', {})
				.then(res => {
					this.logo_img = res.list[1].url;
				})
				.catch(e => {
					// this.$toast.fail(e);
				});
		}
	}
};
</script>

<style lang="less" scoped>
.aboutOurs {
	.contact {
		padding: 0.3rem 0;
		.ico {
			padding: 1rem 0;
			font-size: .24rem;
			text-align: center;
			img {
				display: block;
				width: 100px;
				margin: auto;
			}
		}

		.contact-item {
			height: .88rem;
			border-bottom: 1px solid #EDEDED;
			// padding: 0 .25rem;
			font-size: 0.28rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			// &:last-child {
			// 	border-bottom: 0;
			// }
			.icon {
				width: .48rem;
				display: block;
				// margin-right: .12rem;
			}
			.label {
				// margin-right: .25rem;
				color: #171717;
				font-weight: bold;
			}
		}
	}
}

// --------20200411[start]------
.layoutBox {
	height: calc(100vh - 1.76rem);
	padding: 0.25rem 0.3rem;
}
</style>
