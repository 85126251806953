<template>
  <div class="main">
    <img style="width:16px;height:16px" src="@/assets/imgs/icon/search_new.png" alt="">
    <input v-model="value" type="text" :placeholder="placeholder">
    <div class="btn" @click="handleSearch">{{ $t("tba.searchfor") }}</div>
  </div>
</template>
<script>
export default {
  props:['placeholder'],
  data() {
    return {
      value: ''
    }
  },
  methods: {
    handleSearch() {
      const {value} = this
      this.$emit('onSearch', value.trim())
    }
  }
}
</script>
<style lang="less" scoped>
  .main{
    height: 32px;
    border-radius: 8px;
    border-color: #3893FB;
    border: 1px solid #3893FB;
    position: relative;
    padding-left: 12px;
    display: flex;
    align-items: center;
    input{
      border:none;
      font-size: 12px;
      margin: 5px;
      flex:1
    }
    .btn{
      height: 32px;
      border-radius: 0 8px 8px 0;
      background-color: #3893FB;
      line-height: 32px;
      color: #fff;
      padding: 0 12px 0 17px
    }
  }
</style>