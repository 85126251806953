<template>
    <div class="billBox">
        <!-- <title-two url="/newHome" title="今日盈利"/> -->
		<common-head url="/newHome" :title="$t('tba.Bill')" :is-return="true">
			<!-- <img slot="right" src="@/assets/imgs/icon/icon_log.png" alt="" /> -->
		</common-head>
		
		<div class="communityInfo">
			<div class="list">
				<div class="item">
					<div class="i_left">
						<div class="yen">{{ today_profit }} U</div>
						<p>{{ $t('tba.Todaysearnings') }}</p>
					</div>
					<div class="i_right">
						<!-- <img src="../../assets/imgs/icon/tiaozhuan_r.png" > -->
					</div>
				</div>
				<div class="split"></div>
				<div class="item" @click="linkToBillback()">
					<div class="i_left">
						<div class="yen">{{ total_profit }} U</div>
						<p>{{ $t('tba.Totalrevenue') }}</p>
					</div>
					<div class="i_right">
						<img src="../../assets/imgs/icon/tiaozhuan_r.png" >
					</div>
				</div>
			</div>
		</div>
		
        <div class="layoutBox bgGray">
            <!-- <van-row gutter="10" class="billNumBox">
                <van-col span="12">
                    <div class="billNum today">
                        <p>{{ $t("tba.todayProfit") }}(USDT)</p>
                        <span>{{ today_profit }}</span>
                    </div>
                </van-col>
                <van-col span="12">
                    <div class="billNum cumulative" @click="linkToBillback()">
                        <p>{{ $t("tba.AccumulatedProfit") }}(USDT)</p>
                        <span>{{ total_profit }}</span>
                    </div>
                </van-col>
            </van-row> -->
            <div class="billList">
				<h3 class="caption">{{ $t('geren.gainRecording') }}</h3>
                <van-list v-model="billLoading" :finished="billFinished" :finished-text="$t('cashFlow.NoMore')"
                          :loading-text="$t('common.loading')" @load="onLoadcashFlow">
                    <div class="billItem" v-for="(item, index) in billList" :key="index">
                        <p class="billTitle">
							<span class="mainFontLighterColor">{{item.update_time}}</span>
							<i class="mainFontLightColor">{{ $t("tba.order") }}</i>
							<strong class="mainFontColor">{{item.order_id}}</strong>
                        </p>
                        <van-row>
                            <van-col span="8">
                                <div class="billCent">
                                    <span>{{ item.currency_pair }}</span>
                                    <p>{{ $t("tba.DealFor") }}</p>
                                    <i></i>
                                </div>
                            </van-col>
                            <van-col span="8">
                                <div class="billCent">
                                    <span>{{ exchangeNameFilter(item.web) }}</span>
                                    <p>{{ $t("tba.exchange") }}</p>
                                    <i></i>
                                </div>
                            </van-col>
                            <van-col span="8">
                                <div class="billCent">
                                    <span class="billGreen">{{ item.profit }} </span>
                                    <p>{{ $t("tba.Profit") }}({{item | danwei}})</p>
                                </div>
                            </van-col>

                        </van-row>
                    </div>
                </van-list>
            </div>
        </div>
    </div>
</template>
<script>
    // import TitleTwo from "../../components/titleTwo";
	import commonHead from '../../components/commonHead';
    export default {
        components: {commonHead},
        data() {
            return {
                billLoading: false,
                billFinished: false,
                billData: [],
                billList: [],
                billPage: 1,
                total_profit: 0,
                today_profit: 0,
            };
        },
        watch: {
            take(news, old) {
                this.billPage = 1;
                this.billFinished = false;
                this.billList = [];
            },
        },
        filters: {
            // exchangeNameFilter(web) {
            //     if (web == "huobi") {
            //         return $t("tba.huobi");
            //     } else if (web == "bian") {
            //         return $t("tba.bian");
            //     } else if (web == "OKEx") {
            //         return $t("tba.OKEx");
            //     }
            // },
			danwei(v) {
				if(v) {
					let arr = v.currency.split('_')
					let arr1 = v.currency_pair.split('/')
					if(arr[1] === 'usd') {
						return arr1[0]
					}else {
						return arr1[1]
					}
				}
			}
        },
        created() {
            // this.getBillList();
            // this.billPage = 1;
        },
        methods: {
			exchangeNameFilter(e) {
			    if (e == "huobi") {
			        return this.$t("tba.huobi");
			    } else if (e == "bian") {
			        return this.$t("tba.bian");
			    } else if (e == "okex") {
			        return this.$t("tba.OKEx");
			    }
			},
            onClickLeft() {
                this.$router.push("/newhome");
            },
            to_bill() {
                this.$router.push("/bill");
            },
            profit_bill() {
                this.$router.push("/bill_sum");
            },

            getBillList() {
                this.$post2("Robot/api/index/getBillList", {
                    page: this.billPage++,
                }).then((res) => {
                    console.log(res);
                    this.billData = res.list;
                    this.total_profit = res.total_profit;
                    this.today_profit = res.today_profit;
					for (let i = 0; i < this.billData.length; i++) {
					    this.billList.push(this.billData[i]);
					}
					// console.log(this.shouyiList);
					// 加载状态结束
					this.billLoading = false;
					// 数据全部加载完成
					
					console.log(this.billData);
					if (this.billData.length < 1) {
					    this.billFinished = true;
					}
                });
            },

            onLoadcashFlow() {
                this.getBillList();
            },
			linkToBillback() {
			  this.$router.push("/bill_back");
			},
        },
    };
</script>
<style lang="less" scoped>
	body {
		background-color: #F5F5F5;
	}
    .billBox {
        .billNumBox{
            border-bottom: 0.2rem solid RGBA(246, 248, 249, 1);
            // padding: 0.2rem 0 0.5rem;
        }
        .billNum {
            text-align: center;
            padding: 0.4rem 0rem 0.6rem 0rem;


            p {
                font-size: 0.27rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #fff;
            }

            span {
                display: block;
                margin-top: 0.2rem;
                font-size: 0.36rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #fff;
            }
        }
		.cumulative{
			background: url(../../assets/imgs/bill/ljyl.png) no-repeat;
			background-size: 100% 100%;
		}
		.today{
			background: url(../../assets/imgs/bill/jryl.png) no-repeat;
			background-size: 100% 100%;
		}
        .billList {
            margin: 0 0.3rem;

            .billItem {
				background: #FFFFFF;
				border-radius: .12rem;
				margin-bottom: .1rem;
                // padding-bottom: 0.26rem;
                // border-bottom: 1px solid #f5f5f5;

                .billTitle {
                    // margin-top: 0.29rem;
                    font-weight: 400;
                    color: #02172E;
					line-height: .4rem;
					padding: .25rem .25rem .15rem;
					border-bottom: 1px solid #EDEDED;

                    i {
                        margin-right: 0.15rem;
                        font-size: 0.29rem;
                        vertical-align: 0.01rem;
                    }

                    span {
                        float: right;
                        font-size: 0.24rem;
                        font-family: Bahnschrift;
                        font-weight: 300;
                        color: #B5B4BA;
                        // margin-top: 0.1rem;
                    }
					
					strong {
						font-family: Bahnschrift;
					}
                }

                .billCent {
                    // margin-top: 0.3rem;
                    position: relative;
                    text-align: center;
					padding: .25rem;

                    p {
                        margin-top: 0.12rem;
                        font-size: 0.24rem;
                        color: #8E8AA5;
                    }

                    span {
                        display: block;
                        font-size: 0.28rem;
                        font-family: Bahnschrift;
                        font-weight: 400;
                        color: #02172E;
                        height: 0.3rem;
                    }

                    .billGreen {
                        font-size: 0.33rem;
                        font-family: Bahnschrift;
                        font-weight: 600;
                        color: #23A453;
                    }

                    i {
                        width: 1.5px;
                        height: 0.6rem;
                        background: #F5F5F5;
                        position: absolute;
                        right: 0;
                        top: 0.4rem;
                    }
                }
            }

            .billItem:last-child {
                border-bottom: none;
            }
        }
		
		
    }

// ----------20200410[start]---------
.communityInfo {
	// padding: .6rem .2rem 0 .6rem .2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2.2rem;
	background: #FFFFFF;
	box-shadow: 0 2px 18px 0px rgba(202, 202, 202, 0.3);
	border-radius: .12rem;
	margin: 0 .3rem .5rem;
	position: relative;
	z-index: 2;
	.list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.item {
			color: #666666;
			font-size: .28rem;
			line-height: .4rem;
			width: 3rem;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			.yen {
				color: #FF9872;
				font-size: .52rem;
				font-family: Bahnschrift;
				line-height: .6rem;
				margin-bottom: .1rem;
			}
			.i_right {
				margin-left: 0.32rem;
				img {
					width: 0.15rem;
					height: 0.26rem;
				}
			}
		}
		.split {
			width: 1px;
			height: .6rem;
			background: #E7E7E7;
		}
	}
}

.caption {
	font-size: 0.32rem;
	font-family: PingFang SC;
	font-weight: bold;
	color: #373737;
	margin-bottom: .25rem;
}
.layoutBox {
	padding-top: 1.6rem;
	margin-top: -1.6rem;
	min-height: calc(100vh - 1.8rem);
	position: relative;
	z-index: 1;
}
// ----------20200410[end]---------
</style>
