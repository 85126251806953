<template>
	<div class="comlist" @click="routerTo">
		<div class="label">
			<span class="icon"><slot name="icon"></slot></span>
			{{ label}}
		</div>
		<div class="info">
			<i class="icon_arrR"></i>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'list',
		props: {
			label: {
				type: String,
				default: ''
			},
			linkUrl: {
				type: String,
				default: '/'
			},
		},
		methods: {
			routerTo() {
				if (this.linkUrl && this.linkUrl !== '/') {
				    this.$router.push(this.linkUrl);
				}
			},
		}
	}
</script>

<style scoped lang="less">
	.comlist {
		padding: .4rem 0;
		line-height: .45rem;
		border-bottom: 1px solid #EDEDED;
		display: flex;
		justify-content: space-between;
		.icon {
			width: .4rem;
			height: .4rem;
			margin-right: .12rem;
			img {
				width: 100%;
				display: block;
			}
		}
		.label {
			color: #373737;
			font-size: .28rem;
			display: flex;
			align-items: center;
		}
		.icon_arrR{
			margin-right: 0;
		}
	}
</style>
