<template>
	<div class="container">
		<!-- 文章详情页 -->
		<common-head url="/consultingService" :is-return="true"></common-head>

		<div class="layoutBox bgWhite">
			<div class="article">
				<h3>
					<strong>{{ list.title }}</strong>
				</h3>
				<h4 class="h4Style">{{ list.create_at }}</h4>
				<div class="van-hairline--bottom">&nbsp;</div>

				<p class="pStyle" style="margin-top: 0.3rem; color: #333333" v-html="list.content"></p>
			</div>
		</div>
	</div>
</template>

<script>
import commonHead from '@/components/commonHead';
export default {
	name: 'viewArticle',
	components: { commonHead },
	data() {
		return {
			id: this.$route.query.id,
			list: []
		};
	},
	created() {
		this.gettongzhi();
	},
	methods: {
		//  获取详情
		gettongzhi() {
			this.$post2('Article/Api/Share/getArticle', {
				id: this.id
			})
				.then(res => {
					console.log(res);
					this.list = res;
				})
				.catch(e => {
					console.log(e);
				});
		},
		//   返回按钮
		onClickLeft() {
			this.$router.back(-1);
		}
	}
};
</script>

<style lang="less" scoped>
.h4Style {
	padding-bottom: 0.2rem;
}
.pStyle {
	word-wrap: break-word;
	word-break: break-all;
}
/deep/.pStyle img {
	max-width: 100% !important;
	margin:auto;
}
// .titleGuanLiStyle {
// 	font-size: 0.4rem;
// 	padding-top: 1.5rem;
// }
// .titlePStyle {
// 	margin-bottom: 0.2rem;
// 	margin-top: 0.3rem;
// 	padding-top: 0.1rem;
// 	transform: rotate(180deg);
// }
// .title {
// 	background: #f6f8f9;
// 	height: 2rem;
// 	padding: 0.3rem;
// }

// .tpuschool {
// 	background: #ffffff;
// 	height: 100%;
// }
// .details {
// 	height: 90%;
// 	// margin: 0.1rem 0.3rem 0rem 0.3rem;
// 	background: #ffffff;
// 	border-radius: 5px;
// 	padding: 0.45rem;
// }
</style>

<style scoped lang="less">
.layoutBox {
	padding: 0.3rem;
	min-height: calc(100vh - 1.76rem);
}
</style>
