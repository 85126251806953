<template>
	<div class="whole">
		<!-- <title-two url="/assets2" title="充币" /> -->
		<common-head :title="$t('xinzeng.chongbi')" :is-return="true" @onclickRight="onclickRight">
			<img slot="right" src="@/assets/imgs/icon/icon_search.png" alt="" />
		</common-head>

		<div class="layoutBox bgGray">
			<div class="ulList">
				<div class="list-cell" @click="showPicker = true">
					{{ token }}
					<!-- <van-dropdown-menu><van-dropdown-item position="bottom" v-model="token" :options="token_set" /></van-dropdown-menu> -->
					<div class="right">
						<span class="mainFontLightColor">{{ $t("tba.choosebi") }}</span>
						<i class="icon_arrR" style="margin: 0;"></i>
					</div>
				</div>

				<div class="chainInfo">
					<div class="hd">
						<span>{{ $t('tba.Chainname') }}</span>
						<span v-for="(item, index) in mainchain_set" :key="index" class="mainColor1">{{ item }}</span>
					</div>
					<div class="bd">
						<div class="code"><div id="qrcode" ref="qrcode" style="width: 100%;height: 100%;"></div></div>
						<p class="codeTit">{{ $t('tba.chongbiAddress') }}</p>
						<div class="address mainFontColor">{{ address }}</div>
						<div :data-clipboard-text="address" @click="copyDomValue()" class="copy">{{ $t('tba.copy') }}</div>
					</div>
				</div>

				<div class="chainTips">{{ $t('tba.explain') }}</div>
			</div>
		</div>
		
		<!-- 币种选择 -->
		<van-action-sheet
			  v-model="showPicker"
			  :round="false"
			  :actions="periodChoiceSet"
			  @select="onSelectPeriod"
			></van-action-sheet>
			
	</div>
</template>

<script>
import commonHead from '@/components/commonHead';
import Clipboard from 'clipboard';
// 地址转换二维码插件
import QRCode from 'qrcodejs2';
export default {
	name: 'chargeMoney',
	components: { commonHead },
	data() {
		return {
			show: false,
			token: 'USDT',
			token_set: [],
			// address: "",
			deposit_min: 0,
			mainchain_set: [],
			user_token_set: [],

			flag: true,
			id: '',
			address: '',
			// value1: 0,
			mainchain: 'TRX',
			coinType: 'TRX',
			currencys: '1',
			// 获取币种类型
			currencyType: [
				// 火币
				{ text: '', value: '1' },
				// 币安s
				{ text: '', value: '2' }
			],
			// 20210415----------
			showPicker: false,
			periodChoiceSet: [{ name: 'USDT' }],
		};
	},
	mounted() {
		this.getSupportedTokens();
	},
	methods: {
		onclickRight() {
				  this.$router.push({
					  path: '/walletRecord',
					  query: {
						  cashType: 'deposit'
					  }
				  })
		},
		onSelectPeriod(item) {
				  this.showPeriodChoice = false;
				  this.token = item.name;
				  this.showPicker = false;
		},
		
		copyDomValue() {
			var clipboard = new Clipboard('.copy');
			clipboard.on('success', e => {
				// success("复制成功"); //这里你如果引入了elementui的提示就可以用，没有就注释即可
				this.$toast('复制成功！');
				console.log('复制成功！');
				console.log(e.text);
				// 释放内存
				clipboard.destroy();
			});
			clipboard.on('error', e => {
				// 不支持复制
				console.log('该浏览器不支持自动复制');
				// 释放内存
				clipboard.destroy();
			});
			console.log(this.copy);
		},
		choicetoken(e) {
			console.log(e.token);
			this.token = e.token;
			this.getDepositAddress();
			this.id = e.id;
			this.show = false;
		},
		choice(e) {
			this.token = e.token;
			this.getDepositAddress();
			this.id = e.id;
		},

		qrcode(address) {
			// console.log(address)
			new QRCode('qrcode', {
				width: 256, // 设置宽度，单位像素
				height: 256, // 设置高度，单位像素
				text: address // 设置二维码内容或跳转地址
			});
		},
		// 获取地址
		getDepositAddress() {
			this.$post2('Pay/Api/Index/getDepositAddress', {
				token: this.token,
				mainchain: this.mainchain
			})
				.then(res => {
					document.getElementById('qrcode').innerHTML = '';
					this.address = res.address;
					// this.deposit_min = res.deposit_min;
					this.$nextTick(() => {
						this.qrcode(res.address);
					});
				})
				.catch(err => {
					this.$dialog.alert({
						message: this.$t('xinzeng.buzhichi')
					});
				});
		},
		onClickLeft() {
			this.$router.push('assets');
		},

		//获取支持的币种
		getSupportedTokens() {
			this.$post2('Pay/Api/Index/getSupportedTokens')
				.then(res => {
					this.mainchain_set = res.mainchain_set;
					this.token_set = res.token_set;
					this.mainchain = this.mainchain_set[0];
					console.log(this.mainchain);

					this.getDepositAddress();

					// this.currencyType[0].text = res.TRX[0];
					// this.currencyType[1].text = res.TRX[1];

					// console.log(this.currencyType[0].text);
					// console.log(this.currencyType[1].text);
				})
				.catch(err => {
					this.$toast('请检查网络');
				});
		}
	}
};
</script>

<style scoped lang="less">
.van-dropdown-item__option {
	margin-top: 5rem;
}
.van-cell--clickable {
	margin-top: 7rem;
}
.btnStyle {
	text-align: center;
	padding: 0.1rem;
	/* width: 1rem; */
	/* padding:1rem 1rem 1rem 1rem; */
	color: #ffffff;
	/* padding: 0px; */
	/* height: 0.5rem; */
	/*background: #ecbb34;*/
	border-radius: 5px;
}
.inputStyle {
	border: 0px;
	width: 70%;
}

.copyStyle {
	color: #ecbb34;
	margin: 0rem 0rem 0rem 2.7rem;
}

.imgPStyle {
	padding: 0.1rem 0.2rem 0.1rem 0.2rem;
	font-size: 0.4rem;
	border: 1px solid #14aa41;
	width: 3rem;
	color: #14aa41;
	border-radius: 5px;
}

.imgDiv {
	text-align: center;
	padding: 0.7rem 1.77rem 0rem;
}

.imgStyle {
	margin: 1rem 0 0.6rem 0;
	width: 2.8rem;
}
.bth_1 {
	margin-top: 1rem;
	color: #ff4b04;
	padding: 3px;
	border: 2px solid #ff4b04;
	border-radius: 5px;
}

.daiBi {
	margin-top: 0.7rem;
}
.ulList {
	padding: 0.1rem 0.3rem;
	.list-cell {
		height: 0.88rem;
		line-height: 0.88rem;
		padding: 0 0.25rem;
		background: #ffffff;
		border-radius: 0.12rem;
		margin-bottom: 0.1rem;
		position: relative;
		.right {
			position: absolute;
			z-index: 1;
			right: 0.25rem;
			bottom: 0;
		}
	}
}
.fdStyle {
	background: #f1f1f1;
	height: 100%;
	/* padding: 0.3rem; */
}
.van-icon-arrow-left {
	color: #ffffff;
}

.van-hairline--bottom {
	background: #ecbb34;
	color: #ffffff;
}

.van-nav-bar__title {
	background: #ecbb34;
	color: #ffffff;
}

.whole {
	/* background: #ffffff; */
}
.title {
	background: #f6f8f9;
	height: 2rem;
	padding: 0.3rem;
}
.titleGuanLiStyle {
	font-size: 0.4rem;
	padding-top: 1.5rem;
}
.titlePStyle {
	margin-bottom: 0.2rem;
	margin-top: 0.3rem;
	padding-top: 0.1rem;
	transform: rotate(180deg);
}

.layoutBox {
	// min-height: calc(100vh - 1.76rem);
	min-height: calc(100vh - .88rem);
}
.chainInfo {
	padding: 0.25rem;
	background: #ffffff;
	border-radius: 0.16rem;
	margin-bottom: 1rem;
	.hd {
		color: #666666;
		font-size: 0.28rem;
		line-height: 0.44rem;
		padding-bottom: 0.25rem;
		border-bottom: 1px solid #ededed;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.2rem;
	}
	.bd {
		font-size: 0.24rem;
		line-height: 0.4rem;
		text-align: center;
	}
	.code {
		width: 256px;
		height: 256px;
		margin: 0 auto 0.25rem;
	}
	.codeTit {
		color: #666666;
		margin-bottom: 0.1rem;
	}
	.address {
		margin-bottom: 0.25rem;
	}
	.copy {
		color: #90c4ff;
		display: inline-block;
		padding: 0 0.2rem;
		border: 1px solid #90c4ff;
		border-radius: 0.04rem;
	}
}
.chainTips {
	color: #999999;
	font-size: 0.24rem;
	line-height: 0.35rem;
}
</style>
