<template>
	<div class="container">
		<common-head url="/newHome" :title="$t('tba.article')" :is-return="true"></common-head>
		
		<div class="tabBox bgWhite">
			<van-tabs class="tabsVanStyle" line-width="1rem" title-inactive-color="#373737" title-active-color="#373737" v-model="active">
				<van-tab :title="$t('tba.news')">
					<div class="journalStyle">
						<div>
							<ul class="ulStyle">
								<li @click="detailsTo(item)" v-for="(item, index) in ziXunList" :key="index" class="ulLiStyle">
									<van-row>
										<van-col span="15">
											<div class="caption">{{ item.title }}</div>
											<p class="date">{{ item.create_at }}</p>
										</van-col>
										<van-col span="8" offset="1"><img class="picture" :src="item.picurl" /></van-col>
									</van-row>
								</li>
							</ul>
						</div>
					</div>
				</van-tab>
				<van-tab :title="$t('tba.shangxueyuan')">
					<div class="journalStyle">
						<div>
							<ul class="ulStyle">
								<li @click="detailsTo(item)" v-for="(item, index) in yuanList" :key="index" class="ulLiStyle">
									<van-row>
										<van-col span="15">
											<div class="caption">{{ item.title }}</div>
											<p class="date">{{ item.create_at }}</p>
										</van-col>
										<van-col span="8" offset="1"><img class="picture" :src="item.picurl" /></van-col>
									</van-row>
								</li>
							</ul>
						</div>
					</div>
				</van-tab>
				<van-tab :title="$t('tba.Notice')">
					<van-steps direction="vertical">
						<van-step v-for="(item, index) in xinWenList" :key="index">
							<p class="direcTionPStyle2">
								<strong>{{ item.title }}</strong>
							</p>
							<p class="direcTionPStyle">{{ item.create_at }}</p>
						</van-step>
					</van-steps>
				</van-tab>
			</van-tabs>

			<!-- <tab-bared></tab-bared> -->
		</div>
	</div>
</template>

<script>
import tabBar from '@/components/tabBar/tabBar.vue';
import commonHead from '@/components/commonHead';

export default {
	name: 'consultingService',
	components: { tabBar, commonHead },
	data() {
		return {
			active: 0,
			ziXunList: [],
			xinWenList: [],
			yuanList: [],
			isLoading: false
		};
	},
	mounted() {},
	created() {
		this.getDateList();
	},
	methods: {
		// 点击跳转到详情页面
		detailsTo(e) {
			this.$router.push({
				path: 'viewArticle',
				query: {
					id: e.id
				}
			});
		},

		// 获取两个 模块中的数据
		getDateList() {
			this.$post2('Article/Api/Index/listArticle', { cid: 1 }).then(res => {
				console.log(res);
				this.ziXunList = res.list;
			});
			this.$post2('Im/Api/Index/myNoticeList').then(res => {
				console.log(res);
				this.xinWenList = res.list;
			});
			this.$post2('Article/Api/Index/listArticle', { cid: 2 }).then(res => {
				console.log(res);
				this.yuanList = res.list;
			});
		}
	},

	//下拉刷新
	onRefresh() {
		setTimeout(() => {
			// Toast("刷新成功");
			this.isLoading = false;
			// this.count++;
			this.getDateList();
		}, 1000);
	}
};
</script>

<style lang="less" scoped>
.direcTionPStyle2 {
	font-size: 0.28rem;
	padding-top: 0.2rem;
	padding-bottom: 0.07rem;
	color: #333333;
}
.direcTionPStyle1 {
	font-size: 0.26rem;
	color: #333333;
}
.direcTionPStyle {
	margin-top: 0.2rem;
	font-size: 0.26rem;
	color: #999999;
}
.tabsVanStyle {
	// padding: 0.3rem;
	// height: 3rem;
}
.ulStyle {
	height: 100%;
}

.ulLiStyle {
	// margin-top: 0.3rem;
	// padding: 0.3rem;
	padding: 0.25rem 0;
	border-bottom: 1px solid #ededed;
}
.ulLiStyle .caption {
	color: #333333;
	font-size: 0.28rem;
	font-weight: bold;
	line-height: 0.44rem;
	height: 0.88rem;
	overflow: hidden;
}
.ulLiStyle .date {
	color: #999999;
	font-size: 0.24rem;
	line-height: 0.44rem;
	margin-top: 0.1rem;
}
.ulLiStyle .picture {
	width: 2.25rem;
	height: 1.4rem;
	border-radius: 0.1rem;
}

.pStyle {
	// margin-left: 0.4rem;
	margin-top: 0.2rem;
}

.vanStep p {
	color: #b1b1b1;
	font-size: 0.001rem;
}

.pStyle {
	font-size: 0.2rem;
	color: #404040;
}

.vanStep h3 {
	font-size: 0.2rem;
	margin: 0.1rem;
	color: #404040;
	//   padding-bottom: 0.4rem;
}

.journalStyle {
	// margin-left: 0.4rem;
	// margin-right: 0.4rem;
	background: #ffffff;
	// margin-top: 0.2rem;
	border-radius: 5px;
	height: 100%;
}

.whole {
	background: #ffffff;
}
.title {
	background: #f6f8f9;
	height: 1rem;
	padding: 0.3rem;
}
.bottomBtnStyle {
	width: 100%;
	background: #e1c364;
	border-radius: 5px;
	border: 0px;
}
.titleGuanLiStyle {
	font-size: 0.4rem;
	padding-top: 1.5rem;
}
.titlePStyle {
	margin-bottom: 0.2rem;
	margin-top: 0.3rem;
	padding-top: 0.1rem;
	transform: rotate(180deg);
}
</style>
