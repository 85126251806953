<template>
	<div class="aboutOurs">
		<common-head url="/aboutOurs" :title="$t('tba.aboutus')" :is-return="true"></common-head>

		<div class="layoutBox">
			<div class="contact">
				<div class="tital">{{title}}</div>
				<div class="message" v-for="(item,index) in message.split('\n')" :key="index">
					{{item}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import commonHead from '@/components/commonHead';

export default {
	name: 'aboutOurs',
	components: {
		commonHead
	},
	data() {
		return {
			title: this.$route.query.msg.title,
			message: this.$route.query.msg.content
		};
	},

	created() {
		this.getAbout();
	},

	methods: {
		onClickLeft() {
			this.$router.push('geren');
		},
		getAbout() {
			this.$post2('user/api/index/aboutUs', {}).then(res => {
				this.email = res.email;
				this.weixin = res.weixin;
			});
		}
	}
};
</script>

<style lang="less" scoped>
.aboutOurs {
	.contact {
		padding: 0.35rem 0.5rem;
		background-color: #FFFFFF;
		border-radius: 8px;
		.tital {
			font-family: PingFang SC;
			font-size: 0.28rem;
			color: #2B2B2B;
			font-weight: bold;
		}
		.message {
			margin-top: 0.3rem;
			color: #666666;
			font-size: 12px;
		}
	}
}

// --------20200411[start]------
.layoutBox {
	height: calc(100vh - 0.88rem);
	padding: 0.6rem 0.3rem;
	background-color: #F8F8F8;
}
</style>
