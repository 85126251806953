<template>
	<div class="container">
		<!-- 安全中心 -->
		<common-head url="/newHome" :title="$t('tba.Securitycenter')" :is-return="true"></common-head>
		
		<div class="layoutBox bgWhite">
			<com-list :label="$t('tba.changeLoginPass')" linkUrl="setLoginPwd">
				<img slot="icon" src="../../assets/imgs/icon/icon_lock.png" alt="" />
			</com-list>
			<com-list :label="$t('tba.setTransferPass')" linkUrl="setThePassword">
				<img slot="icon" src="../../assets/imgs/icon/icon_yen.png" alt="" />
			</com-list>
			<com-list :label="$t('tba.setDefaultExchange')" linkUrl="defaultExchange">
				<img slot="icon" src="../../assets/imgs/icon/icon_house.png" alt="" />
			</com-list>
			<com-list :label="$t('Uset.SetEmail')" linkUrl="bindEmail">
				<img slot="icon" src="../../assets/imgs/icon/youxiang.png" alt="" />
			</com-list>
		</div>
	</div>
</template>

<script>
	import commonHead from '@/components/commonHead';
	import comList from './components/list';
	export default {
		name: 'securityCenter',
		components: {
			commonHead,
			comList,
		},
	}
</script>

<style scoped lang="less">
	.layoutBox {
		height: calc(100vh - 1.76rem);
		padding: .1rem .3rem;
	}
</style>
