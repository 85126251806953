<template>
	<div class="commonHeader">
		<div class="head">
			<div class="menuBtn" @click="handleAsideToggle">
				<div class="icon"><img src="@/assets/imgs/icon/icon_menu.png" alt="" /></div>
			</div>
			<ul class="tabTit">
				<li :class="activeIndex == 0 ? 'active' : '' " @click="routerTo(0)">{{ $t('tba.home') }}</li>
				<li :class="activeIndex == 1 ? 'active' : ''" @click="routerTo(1)">{{ $t('tba.quant') }}</li>
			</ul>
			<div class="langBtn" @click="handleChangeLang">
				<div class="icon"><img src="@/assets/imgs/icon/icon_lang.png" alt="" /></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'commonHeader',
		props: {
			activeIndex: {
				type: Number,
				default: 0
			},
		},
	    methods: {
	        handleAsideToggle() {
	            this.$emit('handleAsideToggle')
	        },
			handleChangeLang() {
				let lang = this.$i18n.locale
				// console.log('handleChangeLang', lang)
				if (lang === 'zh') {
					this.$i18n.locale = 'zhf'
					window.localStorage.setItem('lang', 'zhf')
				}
				if (lang === 'zhf') {
					this.$i18n.locale = 'en'
					window.localStorage.setItem('lang', 'en')
				}
				if (lang === 'en') {
					this.$i18n.locale = 'zh'
					window.localStorage.setItem('lang', 'zh')
				}
				this.$emit('handleChangeLang')
			},
			routerTo(index) {
				if (index == this.activeIndex) {
					return
				}
				if (index == 0) {
					this.$router.push('/newHome')
				}
				if (index == 1) {
					this.$router.push('/quantification')
				}
			},
	    }
	}
</script>

<style scoped lang="less">
	.commonHeader {
		position: relative;
		z-index: 100;
		// padding: .88rem .3rem 0;
		padding: 0 .3rem;
		margin-bottom: .3rem;
		background-image: url(../assets/imgs/common_bg.png);
		background-repeat: no-repeat;
		background-position: center top;
		background-size: 100% 3.5rem;
		.head {
			position: relative;
			height: .88rem;
			line-height: .8rem;
		}
		.menuBtn {
			position: absolute;
			left: 0;
			top: 0;
			.icon {
				width: .6rem;
				height: .88rem;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: .4rem;
					height: .4rem;
				}
			}
		}
		.title {
			color: #ffffff;
			font-size: .32rem;
			// font-weight: bold;
			text-align: center;
		}
		.langBtn {
			position: absolute;
			right: 0;
			top: 0;
			.icon {
				width: .6rem;
				height: .88rem;
				display: flex;
				justify-content: center;
				align-items: center;
				img {
					width: .4rem;
					height: .4rem;
				}
			}
		}
		.tabTit {
			color: #FFFFFF;
			font-size: .36rem;
			display: flex;
			padding: 0 2rem;
			justify-content: space-around;
			.active {
				font-size: .4rem;
				font-weight: bold;
				border-bottom: 1px solid #FFFFFF;
			}
		}
	}
</style>
