<template>
	<div class="registBox">
		<div class="registerPage" v-if="is_not_app">
		    <span><strong>Bitpro</strong></span>
		    <van-button class="titleBtnStyle mainBackColor1" type="primary" size="small" @click="$router.push({name: 'download'})">下载</van-button>
		</div>
		<common-head :title="$t('login.registAccount')" :is-return="true" v-else></common-head>
		<div class="registBody">
			<div class="headIcon"><img :src="logo_img" alt=""  v-if="logo_img != ''"/></div>
			<div class="form">
				<div class="login-dex">
					<ul class="c-reg-panel">
<!-- 						<li>
							<div class="c-reg-panel-leftbox">
								<van-row>
									<van-col>
										<img
											style="width: 0.4rem;margin-top: 0.25rem;margin-right: 0.12rem;"
											src="@/assets/imgs/login/icon_mobilephone.png"
											alt=""
										/>
									</van-col>
									<van-col span="20"><input type="text" v-model="dataForm.username" :placeholder="$t('tba.PleaseEnterYourUsername')" /></van-col>
								</van-row>
							</div>
						</li> -->
						<li>
							<div class="c-reg-panel-leftbox" style="display: flex">
								<country-code-selector :countryCode.sync="phoneCountry" style="padding: 0;padding-right: 10px;color: #cccccc;width: 155px;"></country-code-selector>
								<input type="text" v-model="dataForm.phone" :placeholder="$t('login.PleaseEnterYourCellPhoneNumber')" />
							</div>
							<div class="c-reg-panel-rightbox">
								<img :src="rigthImg" v-show="!errorInputShow && userInput" />
								<img :src="errorImg" v-show="errorInputShow" />
							</div>
						</li>
						<li>
							<div class="c-reg-panel-leftbox posRelat">
								<van-row>
									<van-col>
										<img
											style="width: 0.4rem;margin-top: 0.25rem;margin-right: 0.12rem;"
											src="@/assets/imgs/login/icon_verificationcode.png"
											alt=""
										/>
									</van-col>
									<van-col span="20">
										<input type="text" v-model="dataForm.code" :placeholder="$t('login.PleaseEnterVerificationCode')" />
										<div class="getCodeBtn">
											<button href="javascript:;" @click="smscode" :disabled="codeSending">
												<span v-if="codeSending">({{ theTime }})</span>
												<span v-else>{{ $t('login.getCode') }}</span>
											</button>
										</div>
									</van-col>
								</van-row>
							</div>
						</li>
						<li>
							<div class="c-reg-panel-leftbox">
								<van-row>
									<van-col>
										<img
											style="width: 0.4rem;margin-top: 0.25rem;margin-right: 0.12rem;"
											src="@/assets/imgs/login/icon_password.png"
											alt=""
										/>
									</van-col>
									<van-col span="20"><input type="password" v-model="dataForm.password" :placeholder="$t('login.PleaseInputAPassword')" /></van-col>
								</van-row>
							</div>
						</li>
						<li>
							<div class="c-reg-panel-leftbox">
								<van-row>
									<van-col>
										<img
											style="width: 0.4rem;margin-top: 0.25rem;margin-right: 0.12rem;"
											src="@/assets/imgs/login/icon_password.png"
											alt=""
										/>
									</van-col>
									<van-col span="20"><input type="password" v-model="dataForm.repassword" :placeholder="$t('login.PleaseEnterYourPasswordAgain')" /></van-col>
								</van-row>
							</div>
						</li>
						<li>
							<div class="c-reg-panel-leftbox">
								<van-row>
									<van-col>
										<img
											style="width: 0.4rem;margin-top: 0.25rem;margin-right: 0.12rem;"
											src="@/assets/imgs/login/icon_Invitationcode.png"
											alt=""
										/>
									</van-col>
									<van-col span="20"><input type="text" v-model="dataForm.refcode" :placeholder="$t('login.PleaseEnterYourCode')" /></van-col>
								</van-row>
							</div>
						</li>
					</ul>
					<van-checkbox class="agreelaw" v-model="dataForm.agree">
						<template #icon="props">
							<img class="img-icon" style="width: 0.35rem" :src="require('@/assets/imgs/login/' + (props.checked ? 'icon_choose' : 'c') + '.png')" />
						</template>
						<!-- <span style="color: #c3c5c8;" @click="viewUserRegisterProtocol">{{ $t('login.AgreeLaw') }}</span> -->
						<span style="color: #c3c5c8;">{{ $t('login.AgreeLaw') }}</span><span class="mainColor1" @click.stop="routerTo('/userLaw')">{{ $t("login.userLaw") }}</span><span style="color: #c3c5c8;">{{ $t('login.and') }}</span><span class="mainColor1" @click.stop="routerTo('/userLaw1')">{{ $t("login.mianzetiaokuan") }}</span>
					</van-checkbox>
					<!-- <button class="loginButtonQ mainBackColor1" @click="doRegister('user/api/passport/register')">{{ $t('login.regist') }}</button> -->
					<button class="loginButtonQ mainBackColor1" @click="doRegister('user/api/passport/register')">{{ $t('login.regist') }}</button>
					<p class="tologin" @click="$router.push({ name: 'login' })">
						{{ $t('login.hasAccount') }}
						<span class="mainColor1">{{ $t('login.login') }}</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import PuzzleVerification from 'vue-puzzle-verification';
import countryCodeSelector from 'vue-country-code-selector';
import commonHead from '@/components/commonHead';

export default {
	name: 'registerPage',
	components: { countryCodeSelector, PuzzleVerification, commonHead },
	data() {
		return {
			typeRadop: false,
			articleId: 0,
			dataForm: {
				nation: 86,
				username: '', //用户名
				password: '', // 密码
				repassword: '', // 确认密码
				refcode: '', // 邀请码
				sid: '', //短信验证码id
				code: '', //验证码
				phone: '', //手机号
				agree: false, //同意协议
			},
			active: 0,
			is_not_app: true,
			zone_phone: false,
			zone_email: false,
			zone_user: true,
			codeSending: false,
			theTime: 60,
			haveValue: false,
			// ----20200409----
			inputCode: '',
			haveCode: false,
			phoneCountry: 86,
			errorInputShow: false,
			userInput: false,
			rigthImg: require('@/assets/imgs/login/login-right.png'),
			errorImg: require('@/assets/imgs/login/login-error.png'),
			logo_img: ''
		};
	},
	created() {
		this.GetSystemImgList()
		if (this.$route.query.refcode) {
			this.dataForm.refcode = this.$route.query.refcode;
		}
		if (window.TPUBox) {
			this.is_not_app = false;
		}
		// 用户协议查看
		this.getAritcleId();
	},
	methods: {
		routerTo(path) {
			this.$router.push(path)
		},
		// 用户协议内容id获取
		getAritcleId() {
			this.$post2('Article/api/share/getUserRegisterId', {}).then(res => {
				this.articleId = res.id;
			});
		},
		// 用户协议跳转
		viewUserRegisterProtocol() {
			this.$router.push({
				path: '/viewArticle',
				query: { id: this.articleId }
			});
		},
		// isPhoneNumber(phone) {
		// 	if (!/^1[3456789]\d{9}$/.test(phone)) {
		// 		return false;
		// 	}
		// 	return true;
		// },
		// 获取短信验证码
		smscode() {
			// if (!this.isPhoneNumber(this.dataForm.phone)) {
			// 	// this.$toast('请输入正确的手机号');
			// 	this.$toast(this.$t('login.PleaseEnterRightPhoneNumber'));
			// 	return;
			// }
			console.log(this.phoneCountry)
			
			if (this.codeSending) {
				return;
			}
		
			this.theTime = 60;
			this.codeSending = true;
			this.doCountDown();
		
			this.$post2('Security/Index/phonecode', {
				phone: this.dataForm.phone,
				nation: this.phoneCountry
			}).then(res => {
				this.dataForm.sid = res.sid;
			});
		},
		// 短信验证码倒计时
		doCountDown() {
			if (this.theTime > 1) {
				this.theTime--;
				this.codeTimer = setTimeout(() => {
					this.doCountDown();
				}, 1000);
			} else {
				this.codeSending = false;
				this.haveValue = true;
			}
		},
		checkForm() {
			// if (this.dataForm.username == '') {
			// 	// this.$toast('请填写用户名！');
			// 	this.$toast(this.$t('tba.PleaseEnterYourUsername'));
			// 	return false;
			// }
			
			// if (!this.isPhoneNumber(this.dataForm.phone)) {
			// 	// this.$toast('请输入正确的手机号！');
			// 	this.$toast(this.$t('login.PleaseEnterRightPhoneNumber'));
			// 	return false;
			// }
			
			if (this.dataForm.code == '') {
				// this.$toast('请输入验证码！');
				this.$toast(this.$t('login.PleaseEnterVerificationCode'));
				return false;
			}
			
			if (this.dataForm.password == '' || this.dataForm.password.length < 8) {
				// this.$toast('请输入密码！');
				// this.$toast(this.$t('login.PleaseEnterYourPassword'));
				// this.showAlert("密码不能为空且不能小于8位");
				this.$toast(this.$t('tba.passwordMin8'))
				return false;
			}
			
			if (this.dataForm.repassword == '') {
				// this.$toast('请输入确认密码！');
				this.$toast(this.$t('sPay.ConfirmTransactionPassword'));
				return false;
			}
			
			if (this.dataForm.password !== this.dataForm.repassword) {
				// this.$toast('两次密码不一致！');
				this.$toast(this.$t('sPwd.twoPasswordsDoNotMatch'));
				return false;
			}
			
			if (this.dataForm.refcode == '') {
				// this.$toast('请输入邀请码！');
				this.$toast(this.$t('login.PleaseEnterYourCode'));
				return false;
			}
			
			if (this.dataForm.agree == false) {
				// this.$toast.fail('请勾选《用户协议》');
				this.$toast.fail(this.$t('tba.PleaseCheckUserAgreement'));
				return false;
			}else {
				this.dataForm.agree = 1;
			}
			
			return true
		},
		doRegister(reigserUlr) {
			this.checkForm()
			if (!this.checkForm()) {
				return
			}
			let newdata = {
				nation: this.phoneCountry,
				username: this.dataForm.phone, //用户名
				password: this.dataForm.password, // 密码
				repassword: this.dataForm.repassword, // 确认密码
				refcode: this.dataForm.refcode, // 邀请码
				sid: this.dataForm.sid, //短信验证码id
				code: this.dataForm.code, //验证码
				phone: this.dataForm.phone, //手机号
				agree: this.dataForm.agree, //同意协议
			}
			this.$post2(reigserUlr, newdata)
				.then(res => {
					// if(res.code == 200){
					// this.$toast('注册成功！请返回登录页面登录！');
					this.$toast(this.$t('tba.RegistrationSuccessfulReturntoLogin'));
					setTimeout(() => {
						this.$router.push('login');
					}, 2000);
				})
				.catch(e => {
					this.$toast.fail(e);
				});
		},

		onClickLeft() {
			this.$router.push('/login');
		},
		//获取logo图
		GetSystemImgList() {
			this.$post2('User/Api/Passport/GetSystemImgList', {})
				.then(res => {
					this.logo_img = res.list[1].url;
				})
				.catch(e => {
					// this.$toast.fail(e);
				});
		}
	}
};
</script>

<style lang="less">
// ------20200409-----
@import url('../../../assets/css/login.less');
.registBox {
	// padding-bottom: 1.42rem;
	.registBody {
		padding: 0 0.3rem;
		background: #ffffff;
		.headIcon {
			img {
				width: 2.4rem;
				height: 2.4rem;
				display: block;
				margin: 0 auto;
			}
		}
		.agreelaw {
			font-size: 0.24rem;
			// font-family: PingFang SC;
			// font-weight: 400;
			color: #c3c5c8;
			padding-top: 0.25rem;
			.mainColor1{
				color: #90C4FF;
				// border-bottom: 1px solid #90C4FF;
			}
		}
		.tologin {
			font-size: 0.28rem;
			text-align: center;
			color: #999;
			padding-bottom: 0.3rem;
		}
	}
	.registerPage {
		color: #FFFFFF;
	    padding: 0.2rem 0.2rem 0.4rem;
	}
	.titleBtnStyle {
	    background: #FC9216;
	    border: 0px;
	    border-radius: 6px;
	    width: 1.6rem;
	    float: right;
	}
}

/* 我同意字体在有的分辨率下显示不全的问题 */
</style>
