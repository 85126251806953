<template>
  <div class="whole">
    <!-- <title-two url="/geren" title="资产" /> -->
	<common-head url="/newHome" :title="$t('xinzeng.qianbao')" :is-return="true" @onclickRight="onclickRight">
		<img slot="right" src="@/assets/imgs/icon/icon_search.png" alt="" />
	</common-head>
	
	<div class="accountInfo">
		<div class="item">
			<span class="title">{{ $t('tba.AccountassetsUSDT') }}</span>
			<span class="yen">${{ assetsDitale }}</span>
		</div>
	</div>
	<div class="bgMask"></div>
	
	<div class="bgWhite">
		<div class="body1Style">
		  <div>
		    <van-row type="flex" justify="space-between">
		      <van-col span="7">
		        <div
		          class="spanFlex6 sbtn1"
		          @click="chargeMoney"
		          style="color: #fff;"
		        >
		          {{ $t("xinzeng.chongbi") }}
		        </div>
		      </van-col>
		      <van-col span="7" @click="towithdrawMoney">
		        <div class="spanFlex6 sbtn2">{{ $t("xinzeng.tibi") }}</div>
		      </van-col>
		      <van-col span="7" @click="transfer">
		        <div class="spanFlex6 sbtn3">{{ $t("xinzeng.zhuanz") }}</div>
		      </van-col>
		    </van-row>
		  </div>
		</div>
		
		<div class="zhongjiandeDiv" @click="toRecordMore">
		  <strong>{{$t('tba.historyRecord')}}</strong>
		  <img class="moreImg" src="../../assets/imgs/puliceImg/more.png" alt="" />
		</div>
		<div class="bottomDIvStyle">
		  <van-list
		          v-model="billLoading"
		          :finished="billFinished"
		          :finished-text="$t('cashFlow.NoMore')"
		          :loading-text="$t('common.loading')"
		          @load="onLoadcashFlow"
		  >
		    <van-row
		      class="vanRowBoderStyle"
		      v-for="(item, index) in billList"
		      :key="index"
		    >
		      <van-col class="bottomDivRowColStyle" span="17">
		        <p class="mainFontLighterColor bottomDivRowColStyleP2">
		          {{ item.update_at }}
		        </p>
		    	<div class="bottomDivRowColStylePS"><div class="leixin">{{ $t('tba.'+ item.type) }}</div>  <div class="laiqu" v-if="item.from_uid != 0">{{ item.from_username}}</div></div>
		      </van-col>
		      <van-col span="7">
		        <p class="vanCol4PStyle">
		          <span :class="item.amount > 0 ? 'mainColor1' : 'mainColor3'" v-if="item.token == 'USDT'">{{ item.amount }} {{item.token}}</span>
		    	  <span :class="item.amount > 0 ? 'mainColor1' : 'mainColor3'" v-else>{{ item.amount }} {{item.token}}</span>
		        </p>
		      </van-col>
		    </van-row>
		  </van-list>
		</div>
	</div>
    
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import commonHead from '@/components/commonHead';

export default {
  name: "assetssecondEdition",
  components: {
    Loading,
    // TitleTwo,
	commonHead,
  },
  data() {
    return {
      billLoading: false,
      billFinished: false,
      billData: [],
      billList: [],
      billPage: 1,

      listData: [],
      assetsDitale: 0,
      rewardData: 0,
      amount: "",
      frozen: "",
      filterSet: [],
      showPicker: false,
      cashType: "",

      items: [],
      top: 1,
      bottom: 1,
	  isset_tradepwd: null
    };
  },

  filters: {
    amountIsMinus(amount) {
      if (amount.substr(0, 1) == "-") {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    // take(news, old) {
    //   this.billPage = 1;
    //   this.billFinished = false;
    //   this.billList = [];
    // },
  },

  created() {
    this.billPage = 1;
	this.issetTradepwd()
  },
  mounted() {
    this.getFilterSet(), this.getAssets();
    // for (var i = 1; i <= 20; i++) {
    //   this.items.push(i + " - keep walking, be 2 with you.");
    // }
    // this.top = 1;
    // this.bottom = 20;
    //this.getRobbt();
    // this.getSupportedTokens()
  },
  methods: {
	  onclickRight() {
		  this.$router.push('/walletRecord')
	  },
	  // ---------20200410 start------
	  toRecordMore() {
		  this.$router.push("walletRecord");
	  },
	  // ---------20200410 end------
    refresh(done) {
      setTimeout(() => {
        this.billPage = 1;
        this.getCashflow();
        // var start = this.top - 1;

        // for (var i = start; i > start - 10; i--) {
        //   this.items.splice(0, 0, i + " - keep walking, be 2 with you.");
        // }
        // this.top = this.top - 10;
        done();
      }, 1500);
    },
    infinite(done) {
      this.getCashflow();

      setTimeout(() => {
        var start = this.bottom + 1;
        for (var i = start; i < start + 10; i++) {
          this.items.push(i + " - keep walking, be 2 with you.");
        }
        this.bottom = this.bottom + 10;

        done();
      }, 1500);
    },
    //methods中的方法
    changeConfirm(val, index) {
      this.billPage = 1;
      this.cashType = val; //传值
      console.log(index); //索引
      this.showPicker = false;
      console.log(this.cashType);
      this.getCashflow();
      // 如果最开始的数据源是数组对象，我的做法是先把数据源变成一位数组(用forEach遍历每一项，push到一个新数组中)，用于展示，然后在confirm事件中，拿到索引，根据索引就可以拿到数组中所对应的对象
    },

    getFilterSet() {
      this.$post2("Pay/Api/Index/getFilterSet").then((res) => {
        console.log(res);
        this.filterSet = res;
      });
    },
    // 前往互转页面
    transfer() {
		if (this.assetsDitale > 0) {
			if(this.isset_tradepwd == 0) {
				this.$router.push({
						path: '/setThePassword',
						query: {
							homepath: '/assets'
						},
					});
			}else {
				this.$router.push("/transfer");
			}
		} else {
			this.$toast('余额不足！')
		}
    },
    exchange_tt() {
      this.$router.push("switch");
    },
    // 获取资产信息
    getAssets() {
      this.$post2("User/Api/Index/userAsset").then((res) => {
        console.log(res);
        // 总资产是 amout + frozen

        let t = res.USDT.amount + res.USDT.frozen;
        this.assetsDitale = t.toFixed(2);
        // let t2 = res.REWARD.amount + res.REWARD.frozen;
        // this.rewardData = t2.toFixed(2);
        // console.log(typeof res.USDT.amount)
        // this.amount =  res.amount
        // + res.frozen
      });
    },

    // 获取 量化交易
    getRobbt() {
      this.$post("Robot/Api/Index/getTokenPair").then((res) => {
        console.log(res);
      });
    },

    onLoadcashFlow() {
      this.getCashflow();
      // 异步充值记录
      setTimeout(() => {
        for (let i = 0; i < this.billData.length; i++) {
          this.billList.push(this.billData[i]);
        }
        // console.log(this.shouyiList);
        // 加载状态结束
        this.billLoading = false;
        // 数据全部加载完成

        console.log(this.billData);
        if (this.billData.length < 1) {
          this.billFinished = true;
        }
      }, 1000);
    },

    getCashflow() {
      this.$post2("Pay/Api/Index/cashflow", {
        token: "USDT",
        cashType: this.cashType,
        page: this.billPage++,
      }).then((res) => {
		this.billData = res.list;
        // for (let i = 0; i < res.list.length; i++) {
        //   this.billList.push(res.list[i]);
        // }
        // // 加载状态结束
        // this.billLoading = false;
        // // 数据全部加载完成

        // if (res.list.length < 1) {
        //   this.billFinished = true;
        // }

      });
    },
    // 充币
    chargeMoney() {
		console.log(this.isset_tradepwd);
		this.$router.push("/chargeMoney");
    },
    // 提币
    towithdrawMoney() {
		if (this.assetsDitale > 0) {
			if(this.isset_tradepwd == 0) {
				this.$router.push({
							path: '/setThePassword',
							query: {
								homepath: '/assets'
							},
						});
			}else {
				this.$router.push("/withdrawMoney");
			}
		} else {
			this.$toast(this.$t("fahongbao.InsufficientBalancePlease"))
		}
    },
    imgToIndex() {
      this.$router.push("/geren");
    },
    toTransactionSetup() {
      this.$router.push("transactionSetup");
    },
	issetTradepwd() {
	  this.$post2("/user/api/index/issetTradepwd", {
		  noToast: true
	  })
	    .then((res) => {
	      // console.log('哈哈哈啊哈哈哈哈哈');
		  this.isset_tradepwd = res.isset_tradepwd
	    })
	    .catch((e) => {
	      // this.$toast.fail(e);
	    });
	},
  },
};
</script>

<style scoped lang="less">
.moreImg {
  width: 0.6rem;
  /* height: 0.2rem; */
  // margin-left: 5rem;
}

.sspanStyleCol22 {
  margin-left: 0.2rem;
}

.vanRowBoderStyle {
  border-bottom: 1px solid #EDEDED;
  margin: 0 0.3rem;
  padding: 0.15rem 0 .2rem;
}

.bottomDivRowColStyle {
  // padding: 0.1rem;
}

.vanCol4PStyle {
  font-size: 0.28rem;
  text-align: right;
  // color: #CF4E65;
  margin-top: 0.25rem;
  span {
	  font-family: Bahnschrift;
  }
}

.bottomDivRowColStylePS {
  // padding-bottom: 0.1rem;
  font-size: .28rem;
  line-height: .4rem;
  margin-top: .08rem;
  display: flex;
}

.bottomDivRowColStyleP2 {
  font-size: 0.24rem;
  line-height: .35rem;
}

.fontColorStyle {
  // color: #999;
  color: #333;
}

.zhongjiandeDiv {
  height: 0.8rem;
  font-size: 0.32rem;
  padding: 0 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spanFlex6 {
	color: white;
	font-size: 0.32rem;
  text-align: center;
  width: 100%;
  height: .88rem;
  line-height: .88rem;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  &.sbtn1 {
	  background-image: url('../../assets/imgs/wallet/icon_chargemoney.png');
  }
  &.sbtn2 {
  	  background-image: url('../../assets/imgs/wallet/icon_withdrawmoney.png');
  }
  &.sbtn3 {
  	  background-image: url('../../assets/imgs/wallet/icon_transferaccounts.png');
  }
}

.body1Style {
  padding: 0 0.3rem .5rem;
}
.bgMask {
	padding-top: .45rem;
	background: url(../../assets/imgs/wallet/mask.png) no-repeat center top;
	background-size: 100% .45rem;
	margin-top: -.45rem;
}

.whole {
  /* background: #ffffff; */
}

.accountInfo {
	color: white;
	font-size: .24rem;
	padding: .2rem .4rem;
	height: 1.36rem;
	background: linear-gradient(180deg, #989EFF 0%, #629CFB 100%);
	border-radius: .12rem;
	margin: 0 .3rem;
	.item {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.yen {
		font-size: .44rem;
		font-family: Bahnschrift;
	}
}
.leixin {
	width: 2.3rem;
}
</style>
