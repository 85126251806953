<template>
  <div class="whole">
    <!-- <title-two url="/newHome" title="累计盈利" /> -->
	<common-head url="/newHome" :title="$t('tba.Bill')" :is-return="true">
		<!-- <img slot="right" src="@/assets/imgs/icon/icon_log.png" alt="" /> -->
	</common-head>
	
	<div class="communityInfo">
		<div class="list">
			<div class="item" @click="to_bill()">
				<div class="i_left">
					<div class="yen">{{ today_profit }} U</div>
					<p>{{ $t('tba.Todaysearnings') }}</p>
				</div>
				<div class="i_right">
					<img src="../../assets/imgs/icon/tiaozhuan_r.png" >
				</div>
			</div>
			<div class="split"></div>
			<div class="item">
				<div class="i_left">
					<div class="yen">{{ total_profit }} U</div>
					<p>{{ $t('tba.Totalrevenue') }}</p>
				</div>
				<div class="i_right">
					<!-- <img src="../../assets/imgs/icon/tiaozhuan_r.png" > -->
				</div>
			</div>
		</div>
	</div>

    <!-- <div class="titleVanRowDivStyle">
      <van-row gutter="10">
        <van-col span="12">
          <div class="billNum today" @click="to_bill()">
            <p>{{ $t("tba.todayProfit") }}(USDT)</p>
            <span>{{ today_profit }}</span>
          </div>
        </van-col>
        <van-col span="12">
          <div class="billNum cumulative">
            <p>{{ $t("tba.AccumulatedProfit") }}(USDT)</p>
            <span>{{ total_profit }}</span>
          </div>
        </van-col>
      </van-row>
    </div> -->
	<div class="layoutBox bgWhite">
		<!-- <h3 class="caption">{{ $t('tba.Cumulativeincomedaily') }}</h3> -->
		<div class="titleList mainFontColor">{{ $t('tba.Cumulativeincomedaily') }}</div>
		
		<van-list
		  v-model="billLoading"
		  :finished="billFinished"
		  :finished-text="$t('cashFlow.NoMore')"
		  :loading-text="$t('common.loading')"
		  @load="onLoadcashFlow"
		>
		  <div>
		    <div
		      class="bodyDivStyle"
		      v-for="(item, index) in billList"
		      :key="index"
		    >
		      <div class="bodyLiStyle">
		        <div class="bodySpanStyle mainFontColor">{{ item.ymd }}</div>
		        <div>{{ $t("tba.date") }}</div>
		      </div>
		      <div class="bodyLiStyle right">
		        <div class="bodySpanStyle num">{{ item.profit }}</div>
		        <div>{{ $t("tba.Profit") }}(USDT)</div>
		      </div>
		    </div>
		  </div>
		</van-list>
	</div>
    
  </div>
</template>
<script>
import Loading from "../../components/Loading";
import TitleTwo from "../../components/titleTwo";
import commonHead from '../../components/commonHead';
export default {
  components: {
    Loading,
    TitleTwo,
	commonHead,
  },
  data() {
    return {
      billLoading: false,
      billFinished: false,
      billData: [],
      billList: [],
      billPage: 1,
      total_profit: 0,
      today_profit: 0,
    };
  },
  watch: {
    take(news, old) {
      this.billPage = 1;
      this.billFinished = false;
      this.billList = [];
    },
  },
  filters: {
    exchangeNameFilter(web) {
      if (web == "huobi") {
        return "火币";
      } else if (web == "bian") {
        return "币安";
      }
    },
  },
  created() {
    // this.getBillList();
    // this.billPage = 1;
  },
  methods: {
    onClickLeft() {
      this.$router.push("/newhome");
    },
    to_bill() {
      this.$router.push("/bill");
    },
    profit_bill() {
      this.$router.push("/bill_sum");
    },

    getBillList() {
      this.$post2("Robot/api/index/getProfitList", {
        page: this.billPage++,
      }).then((res) => {
        console.log(res);
        this.billData = res.list;
        this.total_profit = res.total_profit;
        this.today_profit = res.today_profit;
      });
    },

    onLoadcashFlow() {
      this.getBillList();
      // 异步充值记录
      setTimeout(() => {
        for (let i = 0; i < this.billData.length; i++) {
          this.billList.push(this.billData[i]);
        }
        // console.log(this.shouyiList);
        // 加载状态结束
        this.billLoading = false;
        // 数据全部加载完成

        console.log(this.billData);
        if (this.billData.length < 1) {
          this.billFinished = true;
        }
      }, 1200);
    },
  },
};
</script>
<style lang="less" scoped>
.whole {
  // background: #ffffff;
}

.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}
.titleList {
  font-weight: bold;
  margin: 0 0.3rem;
}
.billNum {
  text-align: center;
  padding: 0.4rem 0rem 0.6rem 0rem;

  p {
    font-size: 0.27rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #fff;
  }

  span {
    display: block;
    margin-top: 0.2rem;
    font-size: 0.36rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #fff;
  }
}

.cumulative {
  background: url(../../assets/imgs/bill/ljyl.png) no-repeat;
  background-size: 100% 100%;
}

.today {
  background: url(../../assets/imgs/bill/jryl.png) no-repeat;
  background-size: 100% 100%;
}

.bodySpanStyle {
  color: #333;
  font-size: 0.32rem;
  line-height: .45rem;
  margin-bottom: .06rem;
  &.num {
    color: #42854a;
	font-size: .4rem;
	font-family: Bahnschrift;
  }
}

.bodyLiStyle {
  // margin: 0.2rem 0;
  color: #666666;
  font-size: 0.24rem;
  line-height: .35rem;
  &.right {
	  width: 2.4rem;
  }
}

.bodyDivStyle {
  // border-radius: 5px;
  // background: #ffffff;
  margin: 0 0.3rem;
  display: flex;
  justify-content: space-between;
  padding: .25rem 0;
  // padding-bottom: 0.1rem;
  border-bottom: 1px solid #EDEDED;
}
.bodyDivStyle:last-child {
  border-bottom: none;
}

.titleVanRowDivStyle {
  width: 100%;
  background: #ffffff;
}


// ----------20200410[start]---------
.communityInfo {
	// padding: .6rem .2rem 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2.2rem;
	background: #FFFFFF;
	box-shadow: 0 2px 18px 0px rgba(202, 202, 202, 0.3);
	border-radius: .12rem;
	margin: 0 .3rem .5rem;
	position: relative;
	z-index: 2;
	.list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.item {
			color: #666666;
			font-size: .28rem;
			line-height: .4rem;
			width: 3rem;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			.yen {
				color: #FF9872;
				font-size: .52rem;
				font-family: Bahnschrift;
				line-height: .6rem;
				margin-bottom: .1rem;
			}
			.i_right {
				margin-left: 0.32rem;
				img {
					width: 0.15rem;
					height: 0.26rem;
				}
			}
		}
		.split {
			width: 1px;
			height: .6rem;
			background: #E7E7E7;
		}
	}
}

.caption {
	font-size: 0.32rem;
	// font-family: PingFang SC;
	font-weight: bold;
	color: #373737;
	margin-bottom: .25rem;
}
.layoutBox {
	padding-top: 1.6rem;
	margin-top: -1.6rem;
	position: relative;
	z-index: 1;
}
// ----------20200410[end]---------
</style>
