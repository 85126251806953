<template>
	<div class="bill-list-item">
		<div class="hd">
			<div class="caption">
				<div class="left">
					<span class="yen">{{ list.currency_pair | name_one}}</span>
					<span class="yen1">/{{ list.currency_pair | name_two}}</span>
					<span class="type">{{ typeName(list.type) }}</span>
				</div>
				<span class="specialFont">{{ exchange }}</span>
			</div>
			<div class="info">
				<span>{{ $t('tba.Orderid') }} <span class="specialFont">{{ list.order_id }}</span></span>
				<span class="date">{{ list.order_time }}</span>
			</div>
		</div>
		<div class="bd" v-show="showContent">
		<!-- <div class="bd"> -->
			<div class="msg">
				<div class="item1">
					<div class="vlu">{{ list.num_traded }}</div>
					<div class="name">{{ $t('tba.Thenumberoftransactions') }}</div>
				</div>
				<div class="item2">
					<div class="vlu">{{ list.avg_price }}</div>
					<div class="name">{{ $t('tba.Averagetransactionprice') }}</div>
				</div>
				<div class="item3">
					<div class="vlu">{{ list.trade_amount }}</div>
					<div class="name">{{ $t('tba.transactionfee') }}</div>
				</div>
			</div>
			<div class="msg2">
				<div class="item1">
					<div class="vlu">{{ list.times }}</div>
					<div class="name">{{ $t('tba.Replenishmenttimes') }}</div>
				</div>
				<div class="item2">
					<div class="vlu">{{ list.fee }}</div>
					<div class="name">{{ $t('tba.Handlingfee') }}</div>
				</div>
				<div class="item3">
					<div class="vlu">{{ list.profit }}</div>
					<div class="name">{{ $t('tba.Profit') }}</div>
				</div>
			</div>
		</div>
		<div class="fd" @click="handleToggle">
			<img :class="showContent ? 'up' : ''" src="@/assets/imgs/icon/icon_dropdown.png" alt="" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'billList',
		props: {
			list: {
				type: Object,
				default: () => {},
			},
			exchange: {
				type: String,
				default: '',
			}
		},
		data() {
			return {
				showContent: false,
			}
		},
		filters: {
			name_one(v) {
				return v.split('/')[0]  
			},
			name_two(v) {
				return v.split('/')[1]  
			}
		},
		methods: {
			typeName(type) {
				if (type == '1') {
					// return '买入';
					return this.$t('tba.Buyin')
				}else if (type == '0') {
					// return '卖出';
					return this.$t('tba.Sell')
				}
			},
			handleToggle() {
				this.showContent = !this.showContent
			},
		},
	}
</script>

<style scoped lang="less">
	
	.bill-list-item {
		z-index: -99 !important;
		color: #666666;
		font-size: .28rem;
		// background: #F5F5F5;
		background: #FFFFFF;
		border-radius: .12rem;
		margin-bottom: 0.25rem;
		.hd {
			padding: .25rem .2rem;
			.caption {
				height: .4rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: .25rem;
				.yen {
					color: #373737;
					font-size: 0.32rem;
					margin-right: 0.06rem;
				}
				.yen1 {
					color: #999999;
					margin-right: .25rem;
					font-size: 0.24rem;
				}
				.type {
					font-size: .24rem;
				}
			}
			.specialFont {
				color: #7675D8 !important;
				text-transform: uppercase;
			}
			.info {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: .4rem;
				.date {
					color: #373737;
					font-size: .24rem;
				}
			}
		}
		.bd {
			color: #373737;
			// padding: .5rem .2rem  0;
			padding: .3rem .2rem;
			border-top: 1px solid #F5F5F5;
			.msg {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 0.24rem;
				text-align: center;
			}
			.msg2 {
				display: flex;
				align-items: center;
				justify-content: space-between;
				text-align: center;
			}
			.item1{
				width: 30%;
				.vlu {
					color: #373737;
					font-size: 0.28rem;
					margin-bottom: 0.02rem;
				}
				.name {
					color: #999999;
					font-size: 0.24rem;
				}
			}
			.item2{
				width: 30%;
				.vlu {
					color: #373737;
					font-size: 0.28rem;
					margin-bottom: 0.02rem;
				}
				.name {
					color: #999999;
					font-size: 0.24rem;
				}
			}
			.item3{
				width: 30%;
				.vlu {
					color: #373737;
					font-size: 0.28rem;
					margin-bottom: 0.02rem;
					text-align: center;
				}
				.name {
					color: #999999;
					font-size: 0.24rem;
				}
			}
		}
		.fd {
			height: .5rem;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			&::before {
				content: '';
				position: absolute;
				z-index: 1;
				left: 0;
				right: 0;
				top: 50%;
				border-top: 1px solid #F5F5F5;
			}
			img {
				width: .24rem;
				height: .24rem;
				display: block;
				// background: #F5F5F5;
				background: #FFFFFF;
				position: relative;
				z-index: 2;
				&.up {
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);
				}
			}
		}
		
	}
</style>
